// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-author-details-tsx": () => import("./../../../src/components/templates/author-details.tsx" /* webpackChunkName: "component---src-components-templates-author-details-tsx" */),
  "component---src-components-templates-news-details-tsx": () => import("./../../../src/components/templates/news-details.tsx" /* webpackChunkName: "component---src-components-templates-news-details-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

